import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  welcomeUserMsg: boolean = false;
  dashboardHeading:boolean = false;
  changePasswordHeading:boolean = false;
  manageAdminsHeading:boolean = false;
  managePlayersHeading:boolean = false;
  manageRestroHeading:boolean = false;
  manageOrdersHeading:boolean = false;
  manageClubHeading:boolean = false;


  default: boolean;
  lang: string;

  private returnUrl: any;

  profilePic: String = "";
  modalRef: any;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toAsterService: ToastrService
  ) {

    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });


    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });


    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });

    this._header.manageAdminsHeading.subscribe((res) => {
      this.manageAdminsHeading = res;
    });


    this._header.managePlayersHeading.subscribe((res) => {
      this.managePlayersHeading = res;
    });

    this._header.manageRestroHeading.subscribe((res) => {
      this.manageRestroHeading = res;
    });

    this._header.manageOrdersHeading.subscribe((res) => {
      this.manageOrdersHeading = res;
    });    

    this._header.manageClubHeading.subscribe((res) => {
      this.manageClubHeading = res;
    });    

    
  }

  userName: String;
  firstName:String;
  lastName: String;
  allDate:any;


  currentUser:any;
  ngOnInit(): void {
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = this.authService.currentUserValue;
    this.default = true;
    this.lang = "English";
    this.userName = "Admin";
    this.currentUser = this.authService.currentUserValue;
    this.allDate = this.authService.currentUserValue;
    this.userName = this.allDate?.firstName?.toUpperCase();

  }

  logout() {
    this.modalRef.hide();
    
    this.authService.logout({email:this.currentUser.email, password: this.currentUser.password}).subscribe((res:any) =>{
      console.log(res.status);
      if(res.status == 200){
        localStorage.removeItem("currentUser");
      }
      // else{
      //   this.toAsterService.error(res.error.message);
      // }
      
    });
    this.router.navigateByUrl("/auth/login");
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }
}
