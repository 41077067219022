<div class="sidebar" (keydown)="disabledTab($event)">
  <div class="logo"><img src="../../../assets/Logo-Gamedaymeals.svg" alt="logo"></div>
  <div class="header-links">

    <a routerLink="/dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/dashboard_black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/dashboard_white.svg" alt="" height="24" width="24">
    </span>Dashboard</a>

    <a routerLink="/manage-club" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Manage Clubs - Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Manage Clubs - White.svg" alt="" height="24" width="24">
    </span>Manage Clubs</a>


    <a routerLink="/manage-players" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Manage Players - Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Manage Players - White.svg" alt="" height="24" width="24">
    </span>Manage Players</a>

    <a routerLink="/manage-restaurants" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Manage Restaurants - Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Manage Restaurants - White.svg" alt="" height="24" width="24">
    </span>Manage Restaurants</a>


    <a routerLink="/manage-orders" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Manage Orders - Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Manage Orders - White.svg" alt="" height="24" width="24">
    </span>Manage Orders</a>    

    <a routerLink="/manage-admins" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Manage Admins - Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Manage Admins - White.svg" alt="" height="24" width="24">
    </span>Manage Admin Accounts</a>

    <a routerLink="/edit-profile" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Chnage_Password_Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Change_Password_White.svg" alt="" height="24" width="24">
  </span>Change Password</a>


    <a routerLinkActive="active" (click)="openModal(template)" id="logout">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Log Out Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Log Out Black.svg" alt="" height="24" width="24">
    </span>Logout</a>
  </div>
</div>



<!-- Logout confirm -->
<ng-template #template>
  <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
      <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
      <p>Are you sure you <br>want to logout</p>
      <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
          <button class="btn btn-blue" (click)="confirm()" >Yes</button>
      </div>
  </div>
</ng-template>