<div id="forgot-password">
    <div class="container-fluid">
        <div class="row">
            <div class="logo"><img routerLink="/" src="../../../../assets/Logo-Gamedaymeals.svg" alt="logo"></div>
            <div class="col-md-12">
                <div class="login-field">
                    <div class="login-box">
                        <h3>Email has Been Sent</h3>
                        <p class="mb-1">Please check your inbox. We have sent you a new password to your email address.
                        </p>
                        <form action="">
                            <button type="button" class="btn btn-primary" routerLink="/">Sign In</button>
                            <div class="not-recieve mt-3">Didn’t receive password? <a (click)="resend()" style="cursor: pointer;">Resend</a></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>