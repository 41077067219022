import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor() {}
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading =  new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  manageClubHeading = new BehaviorSubject(false);
  manageAdminsHeading = new BehaviorSubject(false);
  managePlayersHeading = new BehaviorSubject(false);
  manageRestroHeading = new BehaviorSubject(false);
  manageOrdersHeading = new BehaviorSubject(false);
}
