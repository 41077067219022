import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class ManageOrdersService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }



    orderList(data:any){
        return this.http.post(this.url + 'admin/orders',data);
    }


    getOrderById(id:any){
        return this.http.get(this.url + 'admin/order/'+id);
    }
}