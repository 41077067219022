import { Injectable, OnInit } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { AuthService } from '../auth/services/auth.service';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { tap } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";



@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        private toastrService: ToastrService

    ) { }

    currentUser:any;


    ngOnInit(): void {
            this.currentUser = this.authService.currentUserValue;
    }

    

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const basicAuthDetails = this.authService.basicAuthentication;
        const currentUser = this.authService.currentUserValue;
        if(currentUser){
            request = request.clone({
                setHeaders: {
                    'accessToken': currentUser.accessToken,
                    'Authorization': 'Basic ' + btoa(basicAuthDetails.userName + ':' + basicAuthDetails.password)
                }
            })
        }else{
            request = request.clone({
                setHeaders: {
                    'Authorization': 'Basic ' + btoa(basicAuthDetails.userName + ':' + basicAuthDetails.password)
                }
            })
        }

        return next.handle(request).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                        if (event.body.status == 401) {
                                if(event.body.error.errorCode == 44){
                                    this.router.navigate(['/auth/login']);
                                    this.toastrService.error("Session expired please re-login again");
                                }else{
                                    this.router.navigate(['/auth/login']);
                                    this.toastrService.error('You are already logged in with different device');
                                }
                        }
                    }
                },
                error => {

                    if (error.status == 403) {
                        localStorage.removeItem('currentUser');
                        this.router.navigate(['/auth/login']);
                        this.toastrService.error(error.error.error.message);
                    }

                    if (error.status == 401) {
                        localStorage.removeItem('currentUser');
                        this.router.navigate(['/auth/login']);
                        this.toastrService.error('You are already logged in with different device');
                    }
                    if (error.status == 503) {
                        if (error.error.errorCode == 101 || error.error.errorCode == 102 || error.error.errorCode == 104) {
                            if (error.error.errorCode == 101) {
                                this.router.navigate(['/auth/login']);
                                this.toastrService.error('You are already logged in with different device');
                            }
                            this.toastrService.error('You are already logged in with different device');
                            this.router.navigate(['/auth/login']);
                            
                        }

                    // } else {
                        // this.toastrService.error('You are already logged in with different device');
                        // this.router.navigate(['/auth/login']);
                    }
                }
            )
        );
    }
}