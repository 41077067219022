import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class RegisterPlayersService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }
    uploadImage(data:any){
        return this.http.post(this.url + "user/upload-image",data)
    }

    updatPlayerProfile(data){
        return this.http.post(this.url + 'admin/edit-player',data);
    }

    getPlayersList(data){
        return this.http.post(this.url + 'admin/list-players',data);
    }

    getPlayerDetails(id:any){
        return this.http.get(this.url + `admin/view-player/${id}`)
    }
}