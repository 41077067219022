import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageAdminsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }


    deleteSubAdmin(id:any){
        // const httpOptions = {
        //     headers: new HttpHeaders({}), body: data
        // };
        return this.http.delete(this.url + `admin/delete/${id}`);
    }

    createAdmin(data:any){
        return this.http.post(this.url + 'admin/createSubAdmin',data);
    }

    updateSubAdmin(data:any){
        return this.http.post(this.url + 'admin/editSubAdmin',data)
    }

    getSubAdminsList(data:any){
        return this.http.post(this.url + '/admin/listSubAdmin', data);
    }
    
}