import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class ManageRestaurantsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    uploadImage(data:any){
        return this.http.post(this.url + "user/upload-image",data)
    }


    getRestaurantDetails(id:any){
        return this.http.get(this.url + `admin/view-restaurant/${id}`);
    }

    changeStatus(data:any){
        return this.http.post(this.url + 'admin/change-restaurant-status',data);
    }

    restaurantList(data){
        return this.http.post(this.url + 'admin/list-restaurant',data);
    }

    updateRestaurant(data){
        return this.http.post(this.url + 'admin/edit-restaurant',data);
    }
}